<template>
	<div class="d-flex flex-wrap justify-center mb-2">
		<v-chip :ripple="false" :color="lookingFor.tfg ? 'success darken-1' : 'error lighten-1'" class="mx-1 my-1">
			<v-btn
				plain
				small
				:loading="loading"
				class="pa-0 white--text"
				@click="
					lookingFor.tfg = !lookingFor.tfg
					callUpdateUserInterests()
				"
			>
				{{ $t('profile.tfg') }}
				<v-icon right>
					{{ lookingFor.tfg ? 'mdi-check' : 'mdi-close' }}
				</v-icon>
			</v-btn>
		</v-chip>
		<v-chip :ripple="false" :color="lookingFor.tfm ? 'success darken-1' : 'error lighten-1'" class="mx-1 my-1">
			<v-btn
				plain
				small
				:loading="loading"
				class="pa-0 white--text"
				@click="
					lookingFor.tfm = !lookingFor.tfm
					callUpdateUserInterests()
				"
			>
				{{ $t('profile.tfm') }}
				<v-icon right>
					{{ lookingFor.tfm ? 'mdi-check' : 'mdi-close' }}
				</v-icon>
			</v-btn>
		</v-chip>
		<v-chip :ripple="false" :color="lookingFor.job ? 'success darken-1' : 'error lighten-1'" class="mx-1 my-1">
			<v-btn
				plain
				small
				:loading="loading"
				class="pa-0 white--text"
				@click="
					lookingFor.job = !lookingFor.job
					callUpdateUserInterests()
				"
			>
				{{ $t('profile.job') }}
				<v-icon right>
					{{ lookingFor.job ? 'mdi-check' : 'mdi-close' }}
				</v-icon>
			</v-btn>
		</v-chip>
		<v-chip :ripple="false" :color="lookingFor.internship ? 'success darken-1' : 'error lighten-1'" class="mx-1 my-1">
			<v-btn
				plain
				small
				:loading="loading"
				class="pa-0 white--text"
				@click="
					lookingFor.internship = !lookingFor.internship
					callUpdateUserInterests()
				"
			>
				{{ $t('profile.internship') }}
				<v-icon right>
					{{ lookingFor.internship ? 'mdi-check' : 'mdi-close' }}
				</v-icon>
			</v-btn>
		</v-chip>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'UserInterestsLooking',
	props: {
		loadingParent: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			lookingFor: {
				tfg: false,
				tfm: false,
				job: false,
				internship: false,
			},
		}
	},
	watch: {
		user(val) {
			if (val) this.lookingFor = this.user.info.lookingFor
		},
	},
	computed: {
		...mapGetters({
			user: 'user/currentUser',
		}),
	},
	created() {
		this.lookingFor = this.user.info.lookingFor
	},
	methods: {
		callUpdateUserInterests() {
			this.loading = true
			this.updateUserInterests({
				lookingFor: this.lookingFor,
			}).then(() => {
				this.loading = false
			})
		},
		...mapActions('settings', ['updateUserInterests']),
	},
}
</script>
